var trackActionWithLabel = function(action, category, label) {
    if (typeof _gaq !== 'undefined' && _gaq != null && _gaq !== undefined) {
        if (label === 'undefined' || label === null || label === undefined) {
            _gaq.push(['_trackEvent',
                category,
                action
            ]);
        }
        else {
            _gaq.push(['_trackEvent',
                category,
                action,
                label
            ]);
        }
    }
};

var trackAction = function(action, category) {
    trackActionWithLabel(action, category, null);
};

var trackCrossSell = function(element, action, source) {
    source = source || jQuery(element).closest("a").data('xsell-source');
    trackAction(action + "-" + source, "cross-sell");
};

var ga = {
    'trackAction': trackAction,
    'trackActionWithLabel': trackActionWithLabel,
    'trackCrossSell': trackCrossSell
};

export {ga};