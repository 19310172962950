export function ieFixes ($) {
  var browser = {
    isIE11: /Edge/.test(navigator.userAgent),
    isEdge: !!window.MSInputMethodContext && !!document.documentMode,
  };

  if (browser.isIE11 || browser.isEdge){
    // Make absolute elements within relative td to position properly
    tableCellRelatives($);
  }

  // NodeList.prototype.forEach
  nodeList_forEach();

  // CustomEvent constructor
  customEventInit();
}

// https://css-tricks.com/absolutely-position-element-within-a-table-cell/
function tableCellRelatives ($) {
  // Get all TD existing with position relative
  var $tableData = filterByPosition($('table tbody td'));

  // Get each TR, parent of TD with position relative
  var $tableRows = $tableData.parent();

  // Iterate each unique TR
  $.uniqueSort($tableRows).each(function (index, tr) {
    var $row = $(tr);

    // Reuse $tableData
    var $tds = filterByPosition($(tr).find('td'));

    var $contentWrapper = $("<div />", {
      "class": "td-relative",
      "css"  : {
        "height"  : $row.outerHeight(),
        "width"   : "100%",
        "position": "relative"
      }
    });

    $tds.wrapInner($contentWrapper);
  });

  /////

  function filterByPosition ($list) {
    return $list.filter(function () {
      return window.getComputedStyle(this).getPropertyValue('position') === 'relative';
    });
  }
}

// https://developer.mozilla.org/en-US/docs/Web/API/NodeList/forEach#Polyfill
function nodeList_forEach(){
  if (window.NodeList && typeof window.NodeList.prototype.forEach !== 'function') {
    NodeList.prototype.forEach = function (callback, thisArg) {
      thisArg = thisArg || window;
      for (var i = 0; i < this.length; i++) {
        callback.call(thisArg, this[i], i, this);
      }
    };
  }
}

// https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent#Polyfill
function customEventInit(){
  if (typeof window.CustomEvent === "function") return false;

  function CustomEvent ( event, params ) {
    params = params || { bubbles: false, cancelable: false, detail: null };
    var evt = document.createEvent( 'CustomEvent' );
    evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
    return evt;
  }

  window.CustomEvent = CustomEvent;
}
