// module add-to-cart.js

var ShoppingCart = (function () {
    var self;
    var modal_id = '#added-to-cart:not(.current)';

    function ShoppingCart($) {
        self = this;
        self.$ = $;
        self.currentStep = null;
        self.selectedSubscriptionId = null;
        self.modal_to_remove = null;
        self.tipButton = null;
        self.tipInstance = null;
        self.varien_form = null;
        self.variantsInstance = null;

        self.$(document).on('reset_variants', function() {
            self.hideToolTips();
        });
    }

    var getOptions = function() {
        self.options = self.options || window.__cartOptions__;
    };

    var extractErrorMessage = function (d) {
        var err = null;
        if (typeof(d.messages) === 'object' && d.messages.constructor === Array) {
            err = d.messages[0];
        } else if ((typeof(d.message) === 'string') && d.message.length) {
            err = d.message;
        }
        return err;
    };

    var updateCartQuantity = function (qty) {
        var qtyString = qty > 99 ? '99+' : qty.toString();
        self.$('.shopping-cart').html('<span class="cart-item-qty in-the-cart">' + qtyString + '</span>');

        if(qty > 0)
            self.$('header .header-icon.your-cart .in-the-cart').show().html(qtyString);
        else
            self.$('header .header-icon.your-cart .in-the-cart').hide();
    };
    /**
     * add current product to cart
     * @param data
     * @param element
     * @param isCrossSell
     * @param callback
     */
    var addToCart = function (data, element, isCrossSell, callback) {
        getOptions();
        postForm(self.options.addToCartUrl, data, element, function (d) {
            // update cart qty in header
            updateCartQuantity(d.items_in_cart);
            // init cross sells
            if (!isCrossSell && !d.is_club_replacement) {
                if(self.$(self.modal_to_remove).length > 0) {
                    self.$(self.modal_to_remove).on('hidden.bs.modal', function() {
                        self.$(self.modal_to_remove).remove();
                        self.modal_to_remove = null;
                    }).modal('hide');
                }
                if (callback) {
                    callback(d);
                }
                self.$('body').append(d.html);
                self.$(modal_id).modal('show');
                if (d.has_cross_sells) {
                    initCrossSells();
                }

                if(d.reload_window) {
                    self.$(modal_id).on('hide.bs.modal', function() {
                        window.location.reload();
                    });
                } else {
                    var selector = modal_id + ':not(.club)';
                    self.$(selector).on('hidden.bs.modal', function() {
                        self.$(selector).remove();
                    })
                }

            } else if (d.is_club_replacement) {
                self.$('body').append(d.html);
                self.$('#club-in-cart-modal')
                    .modal('show');
            }
            else {
                // in upsell section of add to cart
                // update the UX when upsell added directly to the cart
                // TODO: add from cross sells
                //self.$(element).replaceWith('<div class="added-cross-sell-to-cart"><span class="glyphicon glyphicon-ok"></span> Added to Your Cart</div>');
                //self.$("#addtocart-upsell span.subtotal").html(d.subtotal);
            }
        }, "We could not add the product to your cart.");
    };

    var postForm = function (url, data, element, callback, genericError) {
        self.$.post({
            url: url,
            data: data,
            success: function (d) {
                if (!d.success) {
                    var message = extractErrorMessage(d) || genericError;
                    self.site.showError(message);
                    return;
                }
                callback(d);
            },
            error: function (x, s, e) {
                self.site.showError(x.responseText || x.statusText);
            },
            beforeSend: function () {
                self.$("#error-container").removeClass('visible');
                self.$(element).addClass('in-progress').attr('disabled', 'disabled');
            },
            complete: function () {
                self.$(element).removeClass('in-progress').removeAttr('disabled');
            }
        });
    };

    /**
     * init the cross sell gallery
     * @param skipCrossSellEventHooks
     */
    var initCrossSells = function (skipCrossSellEventHooks) {
        var xsell = self.$('#xsell-gallery');
        var items = xsell.find('.xsell-items-container');
        items.slick({
            slidesToShow: 3,
            dots: false,
            infinite: false,
            touchThreshold: 20,
            variableWidth: true,
            adaptiveHeight: true,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        arrows: false
                    }
                },
                {
                    breakpoint: 568,
                    settings: {
                        arrows: false,
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 400,
                    settings: {
                        arrows: false,
                        slidesToShow: 1
                    }
                }
            ]
        }).fadeIn('slow');
        if (skipCrossSellEventHooks !== false) {
            initCrossSellTracking(items);
        }

        //Destroy the carousel and remove the DOM element upon closing the modal
        self.$('#welcome-back.modal').on('hidden.bs.modal', function () {
            items.slick('unslick');
            xsell.find('.xsell-items-container').hide();
            self.$(this).off().remove();
        });
    };

    var initCrossSellTracking = function (items) {
        items.find("a.add-to-cart").click(function (e) {
            trackCrossSell(e.target, 'add-to-cart-click');
            var href = self.$(e.target).closest("a").data('href');
            setLocation(href);
        });

        items.find("a.pdp").click(function (e) {
            trackCrossSell(e.target, 'pdp-click');
            var href = self.$(e.target).closest("a").data('href');
            setLocation(href);
        });
    };

    var bindConfigurableListeners = function(form) {
        form.select('input,select,textarea').change(function() {
            self.varien_form.validator.validate();
        });
    };

    ShoppingCart.prototype = {

        init: function (site) {
            self.site = site;
        },

        addProductToCart: function (form, button, callback) {
            var data = self.$(form).serialize();
            addToCart(data, button, false, callback);
        },

        addCrossSellToCart: function (element, id) {
            var source = self.$(element).data('xsell-source');
            trackCrossSell(element, 'add-to-cart-click', source);
            var data = {
                qty: 1,
                product: id,
                form_key: self.$("input[name='form_key']").val(),
                is_cross_sell: true
            };
            addToCart(data, element, true);
        },

        onAddToCartClick: function (form, button) {
            getOptions();
            if (!this.validateVariants()) {
                return;
            }

            self.addProductToCart(form, button);
        },

        initCrossSellGallery: function (skipCrossSellEventHooks) {
            initCrossSells(skipCrossSellEventHooks);
        },

        addByForm: function (selector, callback) {
            getOptions();

            var $form = self.$(selector);

            if (!$form.hasClass('add-to-cart-form') && !this.validateVariants()) {
                return;
            }
            var modal = $form.closest('.modal');
            modal.addClass('current');
            self.modal_to_remove = '#'+modal.attr('id')+'.current';
            var btn = $form.find('.add-to-cart-btn');
            self.addProductToCart($form, btn, callback);
        },
        allowValidation: function(checkRequired, variants) {
            if(checkRequired) {
                if(document.querySelector('.required-err')) {
                    return false;
                }
            }

            var allow = true;
            if(variants) {
                variants.forEach(function (variant) {
                    if(variant._e.warningElement.innerText.match(/available/i)) {
                        allow = false;
                    }
                });
            }
            return allow;
        },
        setCartQty: function (qty) {
            updateCartQuantity(qty);
        },
        hideToolTips: function() {
            if(self.tipInstance === null) {
                return;
            }

            self.tipInstance.popover('hide');
            self.tipButton.on('hidden.bs.popover', function () {
                self.tipInstance.popover('dispose');
                self.tipInstance = null;
                self.tipButton = null;
            });
        },
        showToolTips: function (messages) {
            var $btn = self.tipButton;
            if(!$btn.length || !messages.length) {
                return;
            }

            var messageElements = '';
            messages.forEach(function(msg) {
                var formattedMessage = 'First select ' + AvsAnSimple.query(msg) + ' "' + msg + '" above.';
                messageElements += '<p class="mb-0"><i class="fas fa-exclamation-circle position-absolute" style="color:#c00; line-height: 1.5;"></i><span class="d-block" style="padding-left: 1rem"> ' + formattedMessage + '</span></p>';
            });

            if(messageElements === '') {
                return;
            }

            self.$($btn).addClass('d-block');
            var config = {
                placement: 'top',
                template: '<div class="popover" role="tooltip"><div class="arrow"></div><div class="popover-body"></div></div>',
                html: true,
                content: messageElements,
                trigger: 'manual'
            };

            self.tipInstance = $btn.popover(config);
            self.tipInstance.popover('show');
        },
        validateVariants: function () {
            if (!self.variantsInstance) {
                return true;
            }

            if(!self.allowValidation(true)) {
                return false;
            }

            var unselectedOptionCount = 0;
            var variantsAttributes = self.variantsInstance.configurableAttributes;
            var invalidVariants = variantsAttributes.filter(function (variant) {
                var selectedOpt = variant._e.selectedOption;
                if (!selectedOpt) {
                    unselectedOptionCount++;
                    return true;
                }

                return variant.options.filter(function (opt) {
                    return selectedOpt.id === opt.id && !opt._f.enabled;
                })[0];
            });

            if(unselectedOptionCount > 0 && window.ga && ga.loaded) {
                var productData = self.variantsInstance;
                var productId = self.variantsInstance.productConfig.config.productId;
                var optionsCount = productData.configurableAttributes.length;

                dataLayer.push({
                    'event': 'variant-options-unselected',
                    'product_id': productId,
                    'no_options_selected': optionsCount == unselectedOptionCount,
                    'options_count': optionsCount,
                    'unselected_options_count': unselectedOptionCount
                });
            }

            if(!invalidVariants.length) {
                return true;
            }

            var topmostWarningEl = invalidVariants.length ? invalidVariants[0]._e.warningElement : false;
            var errorTips = [];

            if(self.allowValidation(false, invalidVariants)) {
                // Iterate invalid swatches and validate
                invalidVariants.forEach(function (variant) {
                    if(!self.allowValidation(false, invalidVariants)) {
                        return;
                    }
                    // Trigger validation warning
                    variant._m.validate();

                    var selectedOption = variant._e.selectedOption;

                    if(!selectedOption) {
                        // Push label into messages array
                        errorTips.push(variant.label);
                    }

                    var currentVariantWarning  = self.$(variant._e.warningElement);
                    if (topmostWarningEl !== false
                        && currentVariantWarning .offset().top < self.$(topmostWarningEl).offset().top
                        && !self.site.isInViewport(variant._e.warningElement)
                    ) {
                        topmostWarningEl = variant._e.warningElement;
                    }
                });
            }

            if(errorTips.length
                && event.currentTarget
                && self.$('.add-btn-wrapper').find(event.currentTarget).length > 0) {
                self.tipButton = self.$('.add-btn-wrapper').find(event.currentTarget);
                self.showToolTips(errorTips);
            }

            // Eval if scroll or not
            if (topmostWarningEl !== false && !self.site.isInViewport(topmostWarningEl)) {
                self.$('html, body').animate({
                    scrollTop: self.$(topmostWarningEl).offset().top
                }, 400);
            }

            return false;
        }
    };

    if (typeof (document.observe) === 'function') {
        /**
         * event listeners
         * These seem useful on PDP only; the event is fired only from the swtach selection on the PDP AFAICT
         * Since prototype.js is removed  from the catalogUI PLP page this check prevents the call there
         * but allows it to run through on the PDP (or other pages where it is defined).
         */

        /**
         * Fetch swatches instance when loaded and save it within the Product
         */
        document.observe('swatches:loaded', function(event) {
            self.variantsInstance = event.memo;
        });
    }

    return ShoppingCart;
})();

export {ShoppingCart}
