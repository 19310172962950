export function scrollSticky () {
  // Get initial element reference
  var element = document.querySelector('[scroll-sticky]');

  // Prevent move forward from this point if no element is set
  if (!element) return false;

  var sticked = false;
  var stickToElement = document.querySelector('[scroll-sticky-to]') || {};
  var minHeightTop = stickToElement.offsetHeight || 0;
  var zIndex = 1018;
  var elementPos = {y: 0, x: 0};
  var threshold = window.pageYOffset + minHeightTop;

  var backup = {};
  backup.position = element.style.position;
  backup.top = element.style.top;
  backup.left = element.style.left;
  backup.zIndex = element.style.zIndex;

  window.addEventListener('scroll', function () {
    // getBoundingClientRect top decreases while scrolling, so the actual Y position is the sum of these two
    elementPos.y = sticked
        ? elementPos.y
        : element.getBoundingClientRect().top + window.pageYOffset;

    elementPos.x = sticked
        ? elementPos.x
        : element.getBoundingClientRect().left + window.pageXOffset;

    threshold = window.pageYOffset + minHeightTop;

    if (
        threshold >= elementPos.y
        && !sticked
    ) {
      stickElement();

    } else if (
        threshold < elementPos.y
        && sticked
    ) {
      unstickElement();
    }

    if (sticked) {
      element.style.left = elementPos.x - window.pageXOffset + 'px';
    } else {
      element.style.left = backup.left;
    }
  }, false);

  window.addEventListener('resize', function () {
    unstickElement();

    elementPos.y = element.getBoundingClientRect().top + window.pageYOffset;
    elementPos.x = element.getBoundingClientRect().left + window.pageXOffset;

    if (
        threshold >= elementPos.y
        && !sticked
    ) {
      stickElement();
    }

    if (sticked) {
      element.style.left = elementPos.x - window.pageXOffset + 'px';
    } else {
      element.style.left = backup.left;
    }
  }, false);

  function stickElement () {
    // Set element to sticked
    sticked = true;
    // Create a decoy element with key characteristics from sticky element and bind them
    var spacerEl = document.createElement('div');
    spacerEl.classList.add('scroll-sticky-decoy');
    spacerEl.style.height = element.offsetHeight + 'px';
    spacerEl.style.padding = window.getComputedStyle(element).padding;
    spacerEl.style.margin = window.getComputedStyle(element).margin;
    element.spacerEl = spacerEl;

    // Add decoy element into the sticky element context to push elements off
    element.insertAdjacentElement('afterend', spacerEl);

    // Handle sticky element
    element.classList.add('sticked');

    element.style.position = 'fixed';
    element.style.top = minHeightTop + 'px';
    element.style.zIndex = zIndex;
  }

  function unstickElement () {
    // Remove decoy element
    if (element.spacerEl && element.parentNode) { // element.parentNode required for unit testing
      element.parentNode.removeChild(element.spacerEl);
      delete element.spacerEl;
    }

    sticked = false;

    element.classList.remove('sticked');

    element.style.position = backup.position;
    element.style.top = backup.top;
    element.style.left = backup.left;
    element.style.zIndex = backup.zIndex;
  }
}