var initKarlsQuantityControl = function () {
    return (function ($) {
        $.fn.karlsQuantityControl = function(options) {
            var c = 'karls-quantity-control';
            var d = c + '__button--disabled';

            // This is the easiest way to have default options.
            var settings = $.extend({
                minValue: 1,
                maxValue: 99,
                decreaseButtonTpl: '<div class="'+c+'__button '+c+'__button--decrease"><i class="fa fa-minus"></i></div>',
                increaseButtonTpl: '<div class="'+c+'__button '+c+'__button--increase"><i class="fa fa-plus"></i></div>',
            }, options);

            // Greenify the collection based on the settings variable.
            return this.each(function () {
                // Declare component instance
                var instance = {};

                // Elements
                instance.$input = $(this);
                instance.$decreaseButton = $(settings.decreaseButtonTpl);
                instance.$increaseButton = $(settings.increaseButtonTpl);

                // Properties
                instance.minValue = settings.minValue;
                instance.maxValue = settings.maxValue;
                instance.value = parseInt(this.value, 10);

                // Methods
                instance.increase = increase;
                instance.decrease = decrease;
                instance.setValue = setValue;

                // Init component
                init();

                // Return binding instance
                return instance.$input.data('ka-quantity-control', instance);

                /////
                function init () {
                    // Build UI
                    var fieldConfig = {
                        'type': 'number',
                        'pattern': '\\d*'
                    };

                    if (settings.min > 0) {
                        fieldConfig.min = settings.minValue;
                    }

                    if (settings.max > 0) {
                        fieldConfig.max = settings.maxValue;
                    }

                    instance.$input
                        .attr(fieldConfig)
                        .wrap('<div class="' + c + '">')
                        .before(instance.$decreaseButton)
                        .after(instance.$increaseButton);

                    // Inject general component layout into instance
                    instance.$component = instance.$input.parent();

                    // Bind events
                    instance.$decreaseButton.on('click', function (event) {
                        event.preventDefault();
                        event.stopImmediatePropagation();
                        instance.decrease(1);
                    });

                    instance.$increaseButton.on('click', function (event) {
                        event.preventDefault();
                        event.stopImmediatePropagation();
                        instance.increase(1);
                    });

                    instance.$input.on('change', function (event) {
                        event.preventDefault();
                        event.stopImmediatePropagation();
                        var value = parseInt(event.target.value, 10);

                        // Since event is recursive, break when there is nothing to update
                        if (isNaN(value)) {
                            instance.setValue(instance.minValue);
                        } else if (value !== instance.value) {
                            instance.setValue(value);
                        }
                    });

                    // Prevent more characters input than it should ("number" type doesn't have "size" prop)
                    instance.$input.on('keydown', function (event) {
                        event.stopImmediatePropagation();

                        // Characters like dash (-) on "number" fields are undefined and can't be validated in the value
                        if (!!event.key.match(/^[.,\-+e]$/i)) {
                            return false;
                        }

                        // If not a number, skip validation
                        if (!event.key.match(/^\d$/)) {
                            return;
                        }

                        // If number...
                        // keydown value is previous to the selected character,
                        // at this point the future value would be the current + the key (which is 100% a number)
                        var futureValue = parseInt(event.target.value + event.key, 10);
                        // If the value would exceed the max value allowed, so set it (threshold)
                        if (futureValue > instance.maxValue && instance.maxValue > 0) {
                            instance.setValue(instance.maxValue);
                            // Avoid add the character to preserve the length
                            return false;
                        }
                    });

                    // Initial UI set
                    instance.setValue(instance.value);
                }

                function increase (amount) {
                    instance.setValue(instance.value + amount);
                }

                function decrease (amount) {
                    instance.setValue(instance.value - amount);
                }

                function setValue (amount) {
                    if (typeof amount !== 'number') {
                        console.warn("Can't set NaN values");
                        return;
                    }

                    var isMaxValue = amount >= instance.maxValue && instance.maxValue > 0;
                    var isMinValue = amount <= instance.minValue && instance.minValue > 0;

                    if (isMaxValue) {
                        instance.value = instance.maxValue;
                        instance.$input.val(instance.maxValue);
                    } else if (isMinValue) {
                        instance.value = instance.minValue;
                        instance.$input.val(instance.minValue);
                    } else {
                        instance.value = amount;
                        instance.$input.val(amount);
                    }

                    // Make sure controls update their state
                    instance.$increaseButton.toggleClass(d, isMaxValue);
                    instance.$decreaseButton.toggleClass(d, isMinValue);
                }
            });
        };
    })(jQuery);
};

export {initKarlsQuantityControl};