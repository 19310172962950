// module navigation.js

var Navigation = (function () {
    var self;

    function Navigation($) {
        self = this;
        self.$ = $;
        self.scrolling = false;
        self.previousTop = 0;
        self.scrollDelta = 10;
        self.scrollOffset = 100;
        self.menuScrollTop = 0;
        self.windowScrollTop = 0;
        self.restoreDocument = restoreDocument;
    }

    var toggleAriaExpanded = function (selector) {
        var k = self.$(selector);
        k.attr('aria-expanded', !(k.attr('aria-expanded') === 'true'));
    };

    var toggleAriaClosed = function (selector) {
        var k = self.$(selector);
        k.attr('aria-expanded', false);
    };

    var resetMobileMenu = function () {
        // Restore scrolls
        self.l0_nav.each(function (index, element) {element.scrollTop = 0;});
        self.l1_nav.each(function (index, element) {element.scrollTop = 0;});
        self.$('.nav-link.expanded').removeClass('expanded');

        resetDesktopMenu();

        self.l1_nav.removeClass('fly');

        toggleAriaClosed(self.burger_button);

        self.l0_nav
            .removeAttr('style')
            .removeClass('locked');
    };

    var resetDesktopMenu = function () {
        self.l0_nav
            .removeClass('open')
            .find('[aria-expanded="true"]')
            .attr('aria-expanded', false);

        self.l1_nav
            .removeClass('open')
            .removeAttr('style')
            .find('[aria-expanded="true"]')
            .attr('aria-expanded', false);

        self.restoreDocument();
    };

    var checkSimpleNavigation = function (currentTop) {
        if (self.previousTop - currentTop > self.scrollDelta) {
            //if scrolling up...
            self.header.removeClass('is-hidden');
            if (currentTop !== 0) {
                self.header.addClass('scrolling');
            }
        } else if (currentTop - self.previousTop > self.scrollDelta && currentTop > self.scrollOffset) {
            //if scrolling down...
            self.header.addClass('is-hidden');
        }
    };

    var autoHideHeader = function () {
        var currentTop = self.window.scrollTop();
        checkSimpleNavigation(currentTop);
        self.previousTop = currentTop;
        self.scrolling = false;
    };

    Navigation.prototype.init = function (site) {
        self._site = site;
        self.$(document).ready(function () {
            self.causes_banner = self.$('#causes-banner');
            self.burger_button = self.$('#karls-nav-brand button.navbar-toggler, .sticky-header .navbar-toggler');
            self.brand_nav = self.$('#karls-nav-brand');
            self.l0_nav = self.$('#karls-nav-categories');
            self.l1_nav = self.$('nav.nav-lvl-1');
            self.l2_nav = self.$('nav.nav-lvl-2');
            self.header = self.$('header.karls-header');
            self.nav_overlay = self.$('.nav-overlay');
            self.contact_sidebar = self.$('.contact-sidebar');
            self.filters_sidebar = self.$('.filters-sidebar');
            self.contact_button = self.$('.contact-button');
            self.filters_button = self.$('#filter-products-button');
            self.sticky_header = self.$('.sticky-header');
            self.body = self.$('body');
            self.window = self.$(window);
            self.html = self.$('html');
            self.bodyMarginTop = 0;

            if (self.header.hasClass('fixed')) {
                self.bodyMarginTop = self.header.outerHeight();
                self.body.css('padding-top', self.bodyMarginTop);
            }

            var l0_nav_links = self.l0_nav.find('a.nav-link-toggler');
            var l0_no_nav_links = self.l0_nav.find('a.nav-link:not(.nav-link-toggler):not(.karls-header-links)');

            self._site.listenBreakpoint('md', function (event) {
                if (event.matches) {
                    self.restoreDocument();
                    resetMobileMenu();
                } else {
                    resetDesktopMenu();
                }
            });

            // Mobile or desktop interaction with main menu in desktop layout
            if ('ontouchstart' in document.documentElement){
                // mobile behavior: level 1 nav flies in from left on click
                l0_nav_links.on('click', function (e) {
                    e.preventDefault();
                    if (self._site.breakpointBelow('md') && self.$(window).width() < self._site.settings.breakpoints.md) {
                        var link = self.$(this);
                        var menu = self.$('#' + link.attr('aria-controls'));
                        self.$('.nav-link.expanded').removeClass('expanded');
                        
                        if (menu.hasClass('open') && menu.hasClass('fly')) {
                            menu.removeClass('open').removeClass('fly');
                            self.l0_nav.removeClass('locked');
                        } else {
                            self.$('.nav-lvl-1-category').each(function() {
                                self.$(this).removeClass('open').removeClass('fly');
                                var $destinationElement = self.$('[aria-controls=' + self.$(this).attr('id') + ']').parent();
                                self.$(this).appendTo($destinationElement);
                            });

                            link.addClass('expanded');

                            menu
                            .addClass('open')
                            .each(function (index, element) {element.scrollTop = 0;});

                            requestAnimationFrame(function () {
                                requestAnimationFrame(function () {
                                    menu.addClass('fly');
                                    self.l0_nav.addClass('locked');
                                    toggleAriaExpanded(link);
                                });
                            });

                            link[0].scrollIntoView();
                        }
                    } else {
                        // Cache current element state
                        var expanded = self.$(this).attr('aria-expanded');

                        // move the element after the target element
                        self.$('.nav-lvl-1-category').each(function() {
                            // get the value of the aria-controls attribute
                            var $destinationElement = self.$('.nav-children-wrapper');

                            // move the current element to the destination element
                            self.$(this).appendTo($destinationElement);
                        });

                        // By default close current menu
                        self.l0_nav.removeClass('open');
                        self.l1_nav.removeClass('open');
                        toggleAriaExpanded(self.l0_nav.find('a.nav-link-toggler[aria-expanded="true"]'));

                        // Open a new menu if cache matches
                        if(!expanded || expanded === "false"){
                            var target = self.$(self.$('#' + self.$(this).attr('aria-controls')));
                            if (target.hasClass('open')) {
                                return;
                            }
                            var pos = self.l0_nav.position();
                            pos.top += self.l0_nav.outerHeight();
                            var p = {top: pos.top, left: 0};
                            self.l0_nav.addClass('open');
                            target.css(p).addClass('open');
                            toggleAriaExpanded($(this));
                        }
                    }
                });
            }
            // No touch device
            else {
                // desktop behavior: expand on hover
                l0_nav_links.on('mouseenter', function () {
                    if (self._site.breakpointAbove('md')) {
                        var target = self.$(self.$('#' + self.$(this).attr('aria-controls')));
                        if (target.hasClass('open')) {
                            return;
                        }

                        l0_nav_links.each(function() {
                            if(self.$(this).closest('.nav-item').length && target.closest('.nav-item').length) {
                                if(self.$(this).attr('id') !== target.closest('.nav-item').attr('id')) {
                                    toggleAriaClosed(self.$(this));
                                }
                            }
                            else {
                                toggleAriaClosed(self.$(this));
                            }
                        });
                        self.l1_nav.removeClass('open');

                        var pos = self.l0_nav.position();
                        pos.top += self.l0_nav.outerHeight();
                        var p = {top: pos.top, left: 0};
                        self.l0_nav.addClass('open');
                        target.css(p).addClass('open');
                        toggleAriaExpanded(self.$(this));
                    }
                });

                // desktop behavior: close on mouse enter for no children the expanded menu
                l0_no_nav_links.on('mouseenter', function() {
                    if (self._site.breakpointAbove('md')) {
                        self.l0_nav.removeClass('open');
                        self.l1_nav.removeClass('open');
                        toggleAriaExpanded(self.l0_nav.find('a.nav-link-toggler[aria-expanded="true"]'));
                    }
                });

                // desktop behavior: close on mouse leave the expanded menu
                self.l1_nav.on('mouseleave', function () {
                    if (self._site.breakpointAbove('md')) {
                        self.l0_nav.removeClass('open');
                        self.l1_nav.removeClass('open');
                        toggleAriaExpanded(self.l0_nav.find('a.nav-link-toggler[aria-expanded="true"]'));
                    }
                });

                // desktop behavior: remove drop shadow and open class on transition end when the drawer slides up
                self.l1_nav.on('transitionend', function (e) {
                    if (self._site.breakpointAbove('md')) {
                        var k = self.$('#' + e.target.id);
                        if (k.hasClass('drawer') && !k.hasClass('open')) {
                            self.l0_nav.removeClass('open');
                        }
                    }
                });
            }

            // mobile behavior: hamburger menu click
            self.burger_button.on('click', function () {
                if (self._site.breakpointBelow('md')) {
                    if (self.burger_button.attr('aria-expanded') === 'true') {
                        // closing
                        resetMobileMenu();
                        self.scrolling = false;
                    } else {
                        //opening
                        self.body.addClass('nav-menu-open');
                        self.l0_nav.toggleClass('open');
                        toggleAriaExpanded(self.burger_button);
                    }
                }
            });

            document.addEventListener('click', function (event) {
                if (event.target.classList.contains('nav-wrapper') &&
                    event.target.id !== 'karls-nav-categories'
                ) {
                    resetMobileMenu();
                    self.scrolling = false;
                }
            });

            // mobile behavior: hamburger menu click
            self.nav_overlay.on('click', function () {
                if (
                    self._site.breakpointBelow('md')
                    && self.burger_button.attr('aria-expanded') === 'true'
                ) {
                    // closing
                    resetMobileMenu();
                    self.scrolling = false;
                }
            });

            // mobile behavior: prevent overscroll when menu is open
            self.l0_nav.on('transitionstart', function(event) {
                // Make sure to listen transition on proper element
                var isCategoriesNav = event.target.id === 'karls-nav-categories';

                if (self._site.breakpointBelow('md') && isCategoriesNav) {
                    if (!self.l0_nav.hasClass('open')) {
                        jQuery('#' + event.target.id).one('transitionend', function() {
                            self.restoreDocument();
                        });
                    }
                }
            });

            // mobile behavior: level 1 nav flies back right when clicking back
            self.l1_nav.find('a.back').on('click', function (e) {
                e.preventDefault();
                var link = self.$(this);
                var menu = self.$(link.data('self'));
                menu.removeClass('fly');
                self.l0_nav.removeClass('locked');
            });

            // mobile behavior: hide level 1 nav when done transitioning
            self.l1_nav.on('transitionend', function (e) {
                if (self._site.breakpointBelow('md')) {
                    var menu = self.$('#' + e.target.id);
                    if (menu.hasClass('drawer') && menu.hasClass('open') && !menu.hasClass('fly')) {
                        // l1_nav closed
                        menu.removeClass('open');
                        var link = self.$(menu.find('a.nav-link.back').data('parent')).find('a.nav-link-toggler');
                        toggleAriaExpanded(link);
                    }
                }
            });

            // mobile behavior: open contact sidebar
            self.contact_button.on('click', function () {
                self.contact_sidebar.addClass('open visible');
                self.html.addClass('scroll-lock');
                self.body.addClass('contact-sidebar-open');
            });

            // mobile behavior: close contact sidebar
            self.contact_sidebar.find('.overlay, .close').on('click', function () {
                self.contact_sidebar.removeClass('open');
                self.html.removeClass('scroll-lock');
                self.body.removeClass('contact-sidebar-open');
            });
            self.contact_sidebar.find('.overlay').on('transitionend', function() {
                if (!self.contact_sidebar.hasClass('open')) {
                    self.contact_sidebar.removeClass('visible');
                }
            });

            // mobile behavior: open filters sidebar
            self.filters_button.on('click', function () {
                self.filters_sidebar.addClass('open visible');
                self.html.addClass('scroll-lock');
                self.body.addClass('filters-sidebar-open');
            });

            // mobile behavior: close filters sidebar
            self.filters_sidebar.find('.overlay, .btn-close-filters').on('click', function () {
                self.filters_sidebar.removeClass('open');
                self.html.removeClass('scroll-lock');
                self.body.removeClass('filters-sidebar-open');
            });
            self.filters_sidebar.find('.overlay').on('transitionend', function() {
                if (!self.filters_sidebar.hasClass('open')) {
                    self.filters_sidebar.removeClass('visible');
                }
            });

            // global behavior: sticky menu thing but only on home page with the fixed header
            if (self.header.hasClass('fixed')) {
                self.window.on('scroll', function () {
                    if (!self.scrolling && !self.html.hasClass('scroll-lock')) {
                        self.scrolling = true;
                        requestAnimationFrame(autoHideHeader);
                    }
                });

                self.header.on('transitionend', function() {
                    if (self.header.hasClass('is-hidden') || self.window.scrollTop() === 0) {
                        self.header.removeClass('scrolling');
                    }
                });
            }

            // global behavior: show sticky header when normal header is not visible
            var headerHeight = self.header.height();
            self.window.on('scroll', function () {
                if (self.window.scrollTop() >= headerHeight) {
                    self.sticky_header.addClass('visible');
                } else {
                    self.sticky_header.removeClass('visible');
                }
            });
        });
    };

    return Navigation;

    /////

    function restoreDocument () {
        // menu closed
        self.html.removeClass('scroll-lock');
        self.body.css({
            'padding-top': self.bodyMarginTop
        }).removeClass('nav-menu-open');
        self.brand_nav.removeClass('fix');
    }
})();

export {Navigation};
