export function initAccordions($) {
  var accordions = document.querySelectorAll('.accordion');
  var activeClass = 'accordion__toggler--active';
  var timeout = 300;

  if (accordions.length < 1) return false;

  // Toggle FAQs
  accordions.forEach(function (acc) {
    acc.querySelectorAll('.accordion__toggler').forEach(function (element) {
      element.addEventListener('click', function (event) {
        event.preventDefault();
        event.stopImmediatePropagation();

        var self = event.currentTarget;

        // When element is not active
        if (!self.classList.contains(activeClass)) {
          toggleActiveElement(self, true);
        }

        toggleActiveElement(acc.querySelector('.' + activeClass));
      });
    });
  });

  return true; // is active

  function toggleActiveElement(el, activate) {

    if (el) {
      var sibling = el.nextElementSibling;

      if (activate) {
        return $(sibling).slideDown(timeout, function () {
          el.classList.add(activeClass)
        });
      }

      return $(sibling).slideUp(timeout, function () {
        el.classList.remove(activeClass)
      });
    }
  }
}
